<template>
  <div class="main position-relative">
    <div
      class="center-image position-absolute top-50 start-50 translate-middle"
    >
      <router-link to="/"><img src="/img/congrats1.png" alt=""/></router-link>
    </div>
  </div>
</template>

<style scoped lang="css">
.center-image {
  width: 50%;
}
.center-image img {
  width: 100%;
}
@media (max-width: 600px) {
  .center-image {
    width: 90%;
  }
}
</style>

<script>
import Session from "@/modules/session";

export default {
  name: "SubscriptionAccepted",
  created() {
    new Session().getSubscription();
  }
};
</script>
